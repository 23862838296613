import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
// import * as Yup from "yup";
import axios from "axios";
import { API_GET_CATEGORY_DATA, API_UPDATE_COURSE_DATA } from "../../api";
import { toast } from "react-toastify";

// const validationSchema = Yup.object().shape({
//   course_name: Yup.string()
//     .required("Course Name is required")
//     .test('capitalized', 'Course Name must start with a capital letter', (value) => {
//       if (value) {
//         return value[0] === value[0].toUpperCase();
//       }
//       return true;
//     }),

//   details: Yup.string()
//     .required("Details is required")
//     .test("wordLimit", "Details should not exceed 1000 words", (value) => {
//       if (value) {
//         const wordCount = value.trim().split(/\s+/).length;
//         return wordCount <= 1000;
//       }
//       return true;
//     }),

//   professional: Yup.string()
//     .required("Professional is required")
//     .test('capitalized', 'Professional must start with a capital letter', (value) => {
//       if (value) {
//         return value[0] === value[0].toUpperCase();
//       }
//       return true;
//     }),

//   pre_requisite: Yup.array()
//     .of(Yup.string())
//     .required("Pre Requisite is required"),

//   program_highlights: Yup.array()
//     .of(Yup.string())
//     .required("Program Highlights is required"),

//   category: Yup.string()
//     .required("Category is required")
//     .test('capitalized', 'Category must start with a capital letter', (value) => {
//       if (value) {
//         return value[0] === value[0].toUpperCase();
//       }
//       return true;
//     }),

//   program_description: Yup.string()
//     .required("Program Description is required")
//     .test('capitalized', 'Program Description must start with a capital letter', (value) => {
//       if (value) {
//         return value[0] === value[0].toUpperCase();
//       }
//       return true;
//     }),
// });

const EditCourse = ({ courseId, course, handleClose, setShowEditModal }) => {
  // console.log("----->", course);
  // console.log("----->", handleClose);
  // const [showModal, setShowModal] = useState({});
  const [categories, setCategories] = useState([]);
  const [syllabusLinks, setSyllabusLinks] = useState({});
  // const [video, setVideo] = useState({});
  const [courseImages, setCourseImages] = useState({});
  // const [instructor_image, setInstructorImage] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(API_GET_CATEGORY_DATA);
        setCategories(
          response.data.filter((category) => category.type === "course")
        );
      } catch (error) {
        // console.log("Error:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (values) => {
    // console.log("clicked submit", values);
    const formData = new FormData();
    formData.append("course_name", values.course_name);
    formData.append("details", values.details);
    formData.append("skill_covered", values.skill_covered);
    // Append other form fields to the formData

    formData.append("program_description", values.program_description);

    formData.append("category", values.category);
    formData.append("professional", values.professional);
    formData.append("videoUrl", values.videoUrl);

    if (syllabusLinks) {
      // console.log("links", syllabusLinks);
      formData.append("syllabus_links", syllabusLinks);
    }

    // if (video) {
    //   formData.append("video", video);
    // }

    if (courseImages) {
      formData.append("course_images", courseImages);
    }

    // Append duration
    // console.log("duration values", values.duration);
    const name = "duration";
    values.duration.forEach((duration, index) => {
      // console.log(name + "[" + index + "]" + ["[days]"], duration.days);
      formData.append(name + "[" + index + "]" + ["[days]"], duration.days);
      formData.append(
        name + "[" + index + "]" + ["[hoursPerDay]"],
        duration.hoursPerDay
      );
      // formData.append("duration.days", duration.days);
      // formData.append("duration.hoursPerDay", duration.hoursPerDay);
    });

    // Append pre_requisite

    values.pre_requisite.forEach((prerequisite, index) => {
      formData.append(`pre_requisite[${index}]`, prerequisite);
    });

    // Append program_highlights
    values.program_highlights.forEach((program_highlights, index) => {
      formData.append(`program_highlights[${index}]`, program_highlights);
    });

    // Append skill_level
    // values.skill_level.forEach((skillLevel, index) => {
    formData.append(`skill_level`, values.skill_level);
    // });

    // Append course_module
    const courseName = "course_module";
    values.course_module.forEach((module, index) => {
      formData.append(
        courseName + "[" + index + "]" + ["[title]"],
        module.title
      );
      formData.append(
        courseName + "[" + index + "]" + ["[description]"],
        module.description
      );

      // formData.append(`course_module[${index}].title`, module.title);
      // formData.append(
      //   `course_module[${index}].description`,
      //   module.description
      // );
    });

    // Append instructor
    // const instructorName = "instructor";
    // values.instructor.forEach((instructor, index) => {
    //   formData.append(
    //     instructorName + "[" + index + "]" + ["[name]"],
    //     instructor.name
    //   );
    //   formData.append(
    //     instructorName + "[" + index + "]" + ["[description]"],
    //     instructor.description
    //   );

    // formData.append(`instructor[${index}].name`, instructor.name);
    // formData.append(
    //   `instructor[${index}].description`,
    //   instructor.description
    // );
    //   if (instructor_image) {
    //     // Use instructor_images instead of instructor_image
    //     formData.append(`instructor_image`, instructor_image); // Use instructor_images[index] to access the image for the specific instructor
    //   }
    // });
    try {
      // console.log("vaalues", values);
      // console.log({ formData });
      await axios.put(`${API_UPDATE_COURSE_DATA}/${courseId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // console.log(response);
      // Refresh the page
      window.location.reload();
      toast.success("Course Updated Successfully.");
    } catch (error) {
      toast.error("Error while updating.");
      // console.log("Error:", error);
    }
  };

  const initialValues = {
    course_name: course.course_name || "",
    details: course.details || "",
    duration: course.duration || [{ days: "", hoursPerDay: "" }],
    professional: course.professional || "",
    pre_requisite: course.pre_requisite || [],
    program_highlights: course.program_highlights || [],
    skill_covered: course.skill_covered || [],
    category: course.category_name || "",
    videoUrl: course.videoUrl || "",
    skill_level: course.skill_level || "",
    course_module: course.course_module || [{ title: "", description: "" }],
    // instructor: course.instructor || [
    //   { name: "", description: "", image: null },
    // ],
    program_description: course.program_description || "",
  };
  return (
    <div className="justify-center items-center flex rounded-lg overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-2">
      <div className="relative w-[1500px] mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none m-2">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form
                className="space-y-4 bg-gray-300 rounded-lg p-6 pt-[1500px]"
                onSubmit={props.handleSubmit}
              >
                {/* <div className="flex justify-between"> */}
                <h1 className="text-2xl font-bold mb-4">Edit Course</h1>
                {/* <IoClose className="text-2xl" onClick={close()}/> */}
                {/* </div> */}

                {/* Course Name */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="course_name" className="font-bold mb-1">
                    Course Name<span className="text-red-700">*</span>
                  </label>
                  <Field
                    type="text"
                    id="course_name"
                    name="course_name"
                    className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                  />
                  <ErrorMessage
                    name="course_name"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="flex flex-col mb-4">
                  <label htmlFor="details" className="font-bold mb-1">
                    Details<span className="text-red-700">*</span>
                  </label>
                  <Field
                    as="textarea" // Use textarea as the component
                    id="details"
                    name="details"
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    style={{ height: "200px" }} // Set the desired height using inline style
                  />
                  <ErrorMessage
                    name="details"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="flex flex-col mb-4">
                  <label htmlFor="skill_covered" className="font-bold mb-1">
                    Skill Covered<span className="text-red-700">*</span>
                  </label>
                  <Field
                    as="textarea" // Use textarea as the component
                    id="skill_covered"
                    name="skill_covered"
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    style={{ height: "200px" }} // Set the desired height using inline style
                  />
                  <ErrorMessage
                    name="skill_covered"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Duration */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="duration" className="font-bold mb-1">
                    Duration<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="duration">
                    {(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.duration.map(
                          (duration, index) => (
                            <div key={index} className="flex items-center mb-4">
                              <div className="w-1/3">
                                <label htmlFor={`duration[${index}].days`}>
                                  Days<span className="text-red-700">*</span>
                                </label>
                                <Field
                                  type="text"
                                  id={`duration[${index}].days`}
                                  name={`duration[${index}].days`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                              </div>
                              <div className="w-1/2 ml-4">
                                <label
                                  htmlFor={`duration[${index}].hoursPerDay`}
                                >
                                  Hours Per Day
                                  <span className="text-red-700">*</span>
                                </label>
                                <Field
                                  type="text"
                                  id={`duration[${index}].hoursPerDay`}
                                  name={`duration[${index}].hoursPerDay`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Professional */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="professional" className="font-bold mb-1">
                    Professional<span className="text-red-700">*</span>
                  </label>
                  <Field
                    type="text"
                    id="professional"
                    name="professional"
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                  />
                  <ErrorMessage
                    name="professional"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Pre Requisite */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="pre_requisite" className="font-bold mb-1">
                    Pre Requisite<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="pre_requisite">
                    {(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.pre_requisite.map(
                          (prerequisite, index) => (
                            <div key={index}>
                              <div className="flex items-center">
                                <Field
                                  type="text"
                                  name={`pre_requisite[${index}]`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="text-red-500 ml-2"
                                >
                                  Remove
                                </button>
                              </div>
                              <ErrorMessage
                                name={`pre_requisite[${index}]`}
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          )
                        )}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          className="text-blue-500 mt-2"
                        >
                          + Add More
                        </button>
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Program Highlights */}
                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="program_highlights"
                    className="font-bold mb-1"
                  >
                    Program Highlights<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="program_highlights">
                    {(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.program_highlights.map(
                          (program_highlights, index) => (
                            <div key={index}>
                              <div className="flex items-center">
                                <Field
                                  type="text"
                                  name={`program_highlights[${index}]`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="text-red-500 ml-2"
                                >
                                  Remove
                                </button>
                              </div>
                              <ErrorMessage
                                name={`program_highlights[${index}]`}
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          )
                        )}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          className="text-blue-500 mt-2"
                        >
                          + Add More
                        </button>
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Skill Level */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="skill_level" className="font-bold mb-1">
                    Skill Level<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="skill_level">
                    {(arrayHelpers) => (
                      <div className="flex space-x-4">
                        <label>
                          <Field
                            type="radio"
                            name="skill_level"
                            value="Beginner"
                            className="mr-1"
                          />
                          Beginner
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="skill_level"
                            value="Intermediate"
                            className="mr-1"
                          />
                          Intermediate
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="skill_level"
                            value="Advanced"
                            className="mr-1"
                          />
                          Advanced
                        </label>
                      </div>
                    )}
                  </FieldArray>
                  <ErrorMessage
                    name="skill_level"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Category */}
                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="category"
                    className=" font-bold mb-1 flex flex-col"
                  >
                    <span>
                      Category<span className="text-red-700">*</span>
                    </span>
                    <span className="font-[300] text-sm mb-1">
                      Please create a category if not created
                    </span>
                  </label>
                  <Field
                    as="select"
                    id="category"
                    name="category"
                    value={course.category_name}
                    className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                  >
                    <option value="">Select a category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {/* Course Modules */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="course_module" className="font-bold mb-1">
                    Module<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="course_module">
                    {(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.course_module.map(
                          (module, index) => (
                            <div key={index} className="flex items-center mb-4">
                              <div className="w-1/3">
                                <label
                                  htmlFor={`course_module[${index}].title`}
                                >
                                  Title<span className="text-red-700">*</span>
                                </label>
                                <Field
                                  type="text"
                                  id={`course_module[${index}].title`}
                                  name={`course_module[${index}].title`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                              </div>
                              <div className="w-1/2 ml-4">
                                <label
                                  htmlFor={`course_module[${index}].description`}
                                >
                                  Description
                                  <span className="text-red-700">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  id={`course_module[${index}].description`}
                                  name={`course_module[${index}].description`}
                                  className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className="text-red-500 ml-2"
                              >
                                Remove
                              </button>
                            </div>
                          )
                        )}
                        <button
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ title: "", description: "" })
                          }
                          className="text-blue-500 mt-2"
                        >
                          + Add More
                        </button>
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Instructors */}
                {/* <div className="flex flex-col mb-4">
                  <label htmlFor="instructor" className="font-bold mb-1">
                    Instructor<span className="text-red-700">*</span>
                  </label>
                  <FieldArray name="instructor">
                    {(arrayHelpers) => (
                      <>
                        {arrayHelpers.form.values.instructor.map(
                          (instructor, index) => (
                            <div key={index}>
                              <div className="flex items-center mb-4">
                                <div className="w-1/3">
                                  <label htmlFor={`instructor[${index}].name`}>
                                    Name<span className="text-red-700">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id={`instructor[${index}].name`}
                                    name={`instructor[${index}].name`}
                                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                  />
                                </div>
                                <div className="w-1/3 ml-4">
                                  <label
                                    htmlFor={`instructor[${index}].description`}
                                  >
                                    Description
                                    <span className="text-red-700">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id={`instructor[${index}].description`}
                                    name={`instructor[${index}].description`}
                                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                  />
                                </div>
                                <div className="w-1/3 ml-4">
                                  <label htmlFor={`instructor[${index}].image`}>
                                    Image<span className="text-red-700">*</span>
                                  </label>
                                  <input
                                    type="file"
                                    id={`instructor[${index}].image`}
                                    name={`instructor[${index}].image`}
                                    onChange={(e) => {
                                      const updatedImage = e.target.files[0];
                                      setInstructorImage(updatedImage); // Update instructor_image directly with the image file
                                    }}
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="text-red-500 ml-2"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          )
                        )}
                        <button
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                              description: "",
                              image: null,
                            })
                          }
                          className="text-blue-500 mt-2"
                        >
                          + Add More
                        </button>
                      </>
                    )}
                  </FieldArray>
                </div> */}

                {/* Program Description */}
                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="program_description"
                    className="font-bold mb-1"
                  >
                    Program Description<span className="text-red-700">*</span>
                  </label>
                  <Field
                    as="textarea"
                    id="program_description"
                    name="program_description"
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    style={{ height: "200px" }}
                  />
                  <ErrorMessage
                    name="program_description"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                {/* Course Video URL */}
                <div className="flex flex-col mb-4">
                  <label htmlFor="videoUrl" className="font-bold mb-1">
                    Course Video URL<span className="text-red-700">*</span>
                  </label>
                  <Field
                    type="text"
                    id="videoUrl"
                    name="videoUrl"
                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    // style={{ height: "200px" }}
                  />
                  <ErrorMessage
                    name="videoUrl"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="flex justify-between w-[80%]">
                  {/* Syllabus Links */}
                  <div className="flex flex-col mb-4">
                    <label htmlFor="syllabus_links" className="font-bold mb-1">
                      Syllabus <span className="text-red-700">*</span>
                    </label>
                    <input
                      type="file"
                      onChange={(e) => setSyllabusLinks(e.target.files[0])}
                    />
                    <label className="block font-[300] text-xs mb-1">
                      Maximum file size 2MBs
                    </label>
                    <ErrorMessage
                      name="syllabus_links"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Course Images */}
                  <div className="flex flex-col mb-4">
                    <label htmlFor="course_images" className="font-bold mb-1">
                      Course Images<span className="text-red-700">*</span>
                    </label>
                    <input
                      type="file"
                      id="course_images"
                      name="course_images"
                      onChange={(e) => setCourseImages(e.target.files[0])}
                    />
                    <label className="block font-[300] text-xs mb-1">
                      Maximum file size 2MBs
                    </label>
                    <ErrorMessage
                      name="course_images"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    // onClick={() =>handleSubmit()}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Submit
                  </button>
                  <button
                    type="close"
                    onClick={() => {
                      setShowEditModal(false);
                    }}
                    className="text-white bg-[#000] ml-10 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
