import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { API_INSERT_WEBINAR_DATA } from "../../api";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .test("capitalized", "Title must start with a capital letter", (value) => {
      if (value) {
        return value[0] === value[0].toUpperCase();
      }
      return true;
    }),

  description: Yup.string().required("Description is required"),

  date: Yup.date().required("Date is required"),

  time: Yup.string()
    .required("Time is required")
    .test("capitalized", "Time must start with a capital letter", (value) => {
      if (value) {
        return value[0] === value[0].toUpperCase();
      }
      return true;
    }),
});

const WebinarForm = () => {
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("date", values.date);
      formData.append("time", values.time);
      formData.append("image", values.image);

      await axios.post(API_INSERT_WEBINAR_DATA, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log("Server Response:", response.data);

      // Show an alert message when the form is submitted successfully
      toast.success("Form submitted successfully!");

      // Reset the form after successful submission
      resetForm();
    } catch (error) {
      toast.error("Error submitting form!");
      console.error("Error:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        date: "",
        time: "",
        image: null,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form className="w-full p-4 bg-gray-100 rounded shadow">
          <div className="mb-4">
            <label htmlFor="title" className="block font-medium mb-1">
              Title<span className="text-red-500">*</span>
            </label>
            <Field
              type="text"
              name="title"
              className="w-full px-3 py-2 border rounded"
            />
            <ErrorMessage
              name="title"
              component="div"
              className="text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="description" className="block font-medium mb-1">
              Description<span className="text-red-500">*</span>
            </label>
            <Field
              as="textarea"
              name="description"
              rows="4"
              className="w-full px-3 py-2 border rounded"
            />
            <ErrorMessage
              name="description"
              component="div"
              className="text-red-500"
            />
          </div>

          <div>
            <label htmlFor="image" className="block font-medium mb-1">
              Image<span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              className="py-2"
              name="image"
              required
              onChange={(event) =>
                setFieldValue("image", event.currentTarget.files[0])
              }
            />
            <label className="block font-[300] text-xs mb-1">
              Maximum file size 2MBs
            </label>
            <ErrorMessage name="image" component="div" />
          </div>

          <div className="mb-4">
            <label htmlFor="date" className="block font-medium mb-1">
              Date<span className="text-red-500">*</span>
            </label>
            <Field
              type="date"
              name="date"
              className="w-full px-3 py-2 border rounded"
            />
            <ErrorMessage
              name="date"
              component="div"
              className="text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="time" className="block font-medium mb-1">
              Time<span className="text-red-500">*</span>
            </label>
            <Field
              type="time"
              name="time"
              className="w-full px-3 py-2 border rounded"
            />
            <ErrorMessage
              name="time"
              component="div"
              className="text-red-500"
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default WebinarForm;
