import React, { useState, useEffect } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useFormikContext,
  useField,
} from "formik";
// import * as Yup from "yup";
import axios from "axios";
import { API_GET_DEPARTMENT_DATA, API_UPDATE_JOB_DATA } from "../../api";
import stateData from "../Job/states.json";
import Select from "react-select";
import { toast } from "react-toastify";
const EditJob = ({ jobId, job, setShowEditModal }) => {
  const [departments, setDepartments] = useState([]);

  const initialValues = {
    job_title: job.job_title || "",
    job_type: job.job_type || "",
    experience: job.experience || "",
    job_profile: job.job_profile || "",
    job_responsibilities: job.job_responsibilities || "",
    requirements: job.requirements || "",
    job_code: job.job_code || "",
    posted_date: "",
    department: job.department_name || "",
    // location: {
    state: job.location.state || "",
    city: job.location.city || "",
    // },
  };
  
  // console.log("----->", job);
  // console.log("----->", jobId);
  const handleStateChange = (selectedOption, setCityOptions) => {
    const selectedStateData = stateData.states.find(
      (state) => state.state === selectedOption.value
    );

    if (selectedStateData) {
      const cities = selectedStateData.city;
      // console.log("----->", cities);

      setCityOptions(
        cities.map((city) => ({
          value: city,
          label: city,
        }))
      );
    } else {
      setCityOptions([]);
      // console.log('check', setCityOption)
    }
  };

  // const validationSchema = Yup.object().shape({
  //   job_title: Yup.string()
  //     .required("Title is required")
  //     .test("capitalized", "Title must start with a capital letter", (value) => {
  //       if (value) {
  //         return value[0] === value[0].toUpperCase();
  //       }
  //       return true;
  //     }),
  
  //   // job_type: Yup.string()
  //   //   .required("Job-Type is required"),
  
  //   experience: Yup.string()
  //     .required("Experience is required")
  //     .test(
  //       "capitalized",
  //       "Experience must start with a capital letter",
  //       (value) => {
  //         if (value) {
  //           return value[0] === value[0].toUpperCase();
  //         }
  //         return true;
  //       }
  //     ),
  
  //   location: Yup.object().shape({
  //     state: Yup.string()
  //       .required("State is required")
  //       .test(
  //         "capitalized",
  //         "State must start with a capital letter",
  //         (value) => {
  //           if (value) {
  //             return value[0] === value[0].toUpperCase();
  //           }
  //           return true;
  //         }
  //       ),
  
  //     city: Yup.string()
  //       .required("City is required")
  //       .test("capitalized", "City must start with a capital letter", (value) => {
  //         if (value) {
  //           return value[0] === value[0].toUpperCase();
  //         }
  //         return true;
  //       }),
  //   }),
  
  //   job_profile: Yup.string()
  //     .required("Job-Profile is required")
  //     .test(
  //       "capitalized",
  //       "Job-Profile must start with a capital letter",
  //       (value) => {
  //         if (value) {
  //           return value[0] === value[0].toUpperCase();
  //         }
  //         return true;
  //       }
  //     ),
  
  //     posted_date: Yup.date().nullable().required("Posted Date is required"),
  //   job_code: Yup.string().required("Job Code is required"),
  //   requirements: Yup.string().required("Requirements is required"),
  
  //   job_responsibilities: Yup.string()
  //     .required("Responsibilities are required")
  //     .test(
  //       "wordLimit",
  //       "Responsibilities should not exceed 1000 words",
  //       (value) => {
  //         if (value) {
  //           const wordCount = value.trim().split(/\s+/).length;
  //           return wordCount <= 1000;
  //         }
  //         return true;
  //       }
  //     ),
  // });

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(API_GET_DEPARTMENT_DATA);
        setDepartments(response.data);
      } catch (error) {
        // console.log("Error fetching departments:", error);
      }
    };
    
    fetchDepartments();
  }, [job]);

  const handleSubmit = async (values, { setSubmitting }) => {
    // console.log("submitting job", values);
    const formdata = new FormData();
    formdata.append("job_title", values.job_title);
    formdata.append("job_type", values?.job_type);
    formdata.append("experience", values?.experience);
    formdata.append("job_profile", values?.job_profile);
    formdata.append("job_responsibilities", values?.job_responsibilities);
    formdata.append("job_code", values?.job_code);
    formdata.append("requirements", values?.requirements);
    formdata.append("posted_date", values?.posted_date);
    formdata.append("department", values?.department);
    formdata.append("state", values?.location?.state);
    formdata.append("city", values?.location?.city);
    try {
      const response = await axios.put(
        `${API_UPDATE_JOB_DATA}/${jobId}`,
        values
      );

      console.log(response);
      // Refresh the page
      window.location.reload();
      toast.success("Updated successfully.");
    } catch (error) {
      toast.error("Error in Updating.");
      // console.log("Error:", error);
    }
    setSubmitting(false);
  };

  const [cityOptions, setCityOptions] = useState([]);

  const jobTypeOptions = [
    { value: "fresher", label: "Fresher" },
    { value: "part-time", label: "Part-Time" },
    { value: "freelancer", label: "Freelancer" },
    { value: "full-time", label: "Full-time" },
  ];

  const SelectField = ({ options, ...props }) => {
    const [field, meta] = useField(props.name);
    const { setFieldValue } = useFormikContext();

    const handleChange = (selectedOption) => {
      setFieldValue(props.name, selectedOption.value);
      if (props.name === "state") {
        setFieldValue("state", selectedOption.value);
        handleStateChange(selectedOption, setCityOptions);
      } else if (props.name === "city") {
        setFieldValue("city", selectedOption.value);
      }
    };

    const selectedOption = options.find(
      (option) => option.value === field.value
    );

    return (
      <>
        <Select
          {...props}
          options={options}
          onChange={handleChange}
          value={selectedOption}
        />
        {meta.touched && meta.error && (
          <div className="text-red-500">{meta.error}</div>
        )}
      </>
    );
  };

  return (
    <div className="justify-center items-center flex rounded-lg overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-2 pt-2">
      <div className="relative w-full mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none m-2">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
              <Form className="space-y-4 bg-gray-300 rounded-lg p-6 pt-[50px]">
                <h1 className="text-2xl font-bold mb-4">Edit Job</h1>
                <div className="w-full flex justify-between flex-wrap">
                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="job_title" className="font-bold mb-1">
                      Job Title<span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="job_title"
                      name="job_title"
                      // value={title}
                      // onChange={(e)=>{setTitle(e.target.value)}}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="job_title"
                      component="div"
                    />
                  </div>

                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="job_type" className="font-bold mb-1">
                      Job Type<span className="text-red-500">*</span>
                    </label>
                    <SelectField
                      id="job_type"
                      name="job_type"
                      options={jobTypeOptions}
                      // value={type}
                      // onChange={(e)=>{setType(e.target.value)}}
                      className="w-[300px] py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="job_type"
                      component="div"
                    />
                  </div>

                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="experience" className="font-bold mb-1">
                      Experience<span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="experience"
                      name="experience"
                      // value={experience}
                      // onChange={(e)=>{setExperience(e.target.value)}}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="experience"
                      component="div"
                    />
                  </div>

                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="state" className="font-bold mb-1">
                      State<span className="text-red-500">*</span>
                    </label>
                    <SelectField
                      id="state"
                      name="state"
                      options={stateData.states.map((state) => ({
                        value: state.state,
                        label: state.state,
                      }))}
                      // value={job.location.state}
                      // onChange={(e)=>{setState(e.target.value)}}
                      className="w-[300px]  py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="state"
                      component="div"
                    />
                  </div>
                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="job_profile" className="font-bold mb-1">
                      Job Profile<span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="job_profile"
                      name="job_profile"
                      // value={profile}
                      // onChange={(e)=>{setProfile(e.target.value)}}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="job_profile"
                      component="div"
                    />
                  </div>
                  <div className=" w-[48%] flex flex-col mb-4">
                    <label htmlFor="city" className="font-bold mb-1">
                      City<span className="text-red-500">*</span>
                    </label>
                    <SelectField
                      id="city"
                      name="city"
                      options={cityOptions.map((city) => ({
                        value: city.value,
                        label: city.label,
                      }))}
                      // value={job.location.city}
                      // onChange={(e)=>{setCity(e.target.value)}}
                      className="w-[300px] py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="city"
                      component="div"
                    />
                  </div>

                  {/* Add more fields here */}
                </div>
                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="job_responsibilities"
                    className="font-bold mb-1"
                  >
                    Job Responsibilities<span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="textarea"
                    id="job_responsibilities"
                    name="job_responsibilities"
                    // value={responsibilities}
                    // onChange={(e)=>{setResponsibilities(e.target.value)}}
                    className="w-full h-24 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                  />
                  <ErrorMessage
                    className="text-red-500"
                    name="job_responsibilities"
                    component="div"
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <label htmlFor="requirements" className="font-bold mb-1">
                    Requirements<span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="textarea"
                    id="requirements"
                    name="requirements"
                    // value={requirements}
                    // onChange={(e)=>{setRequirements(e.target.value)}}
                    className="w-full h-24 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                  />
                  <ErrorMessage
                    className="text-red-500"
                    name="requirements"
                    component="div"
                  />
                </div>
                <div className="w-full flex justify-between flex-wrap">
                  <div className="w-[48%] flex flex-col mb-4">
                    <label htmlFor="job_code" className="font-bold mb-1">
                      Job Code<span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="job_code"
                      name="job_code"
                      // value={code}
                      // onChange={(e)=>setCode(e.arget.value)}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="job_code"
                      component="div"
                    />
                  </div>

                  <div className="w-[48%] flex flex-col mb-4">
                    <label htmlFor="posted_date" className="font-bold mb-1">
                      Posted Date<span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="date"
                      id="posted_date"
                      name="posted_date"
                      // value={postedDate}
                      // onChange={(e)=>{setPostedDate(e.target.value)}}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    />
                    <ErrorMessage
                      className="text-red-500"
                      name="posted_date"
                      component="div"
                    />
                  </div>

                  <div className="w-[48%] flex flex-col mb-4">
                    <label htmlFor="department" className="font-bold mb-1">
                      Department<span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      id="department"
                      name="department"
                      // value={department}
                      // onChange={(e)=>{setDepartment(e.target.value)}}
                      className="w-[300px] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                    >
                      <option value="">Select a department</option>
                      {departments.map((department, i) => (
                        <option key={i} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-red-500"
                      name="department"
                      component="div"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  // disabled={isSubmitting}
                  // onClick={()=>handleSubmit()}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
                <button
                  type="close"
                  onClick={() => {
                    setShowEditModal(false);
                  }}
                  className="text-white bg-[#000] ml-10 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                >
                  Close
                </button>
              </Form>
           
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditJob;
